<template>
  <div id="home">
    <age
      v-if="!isOfAge"
    />
    <transition name="fade">
      <div
        v-show="isOfAge"
        id="page"
      >
        <core-app-bar />

        <core-drawer />

        <v-slide-y-transition>
          <contact-modal 
            v-show="showModal"
          />
        </v-slide-y-transition>
        <home-title />

        <legend-card />

        <online-store />

        <certifications />

        <powered-by />

        <medal-display-room />

        <mezcal-story />

        <notas-cata />

        <mixologia />

        <events />

        <parallax />

        <core-footer />
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {
      Age: () => import('@/components/landing/Age'),
      Certifications: () => import('@/components/home/Certificaciones'),
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreFooter: () => import('@/components/core/Footer'),
      Events: () => import('@/components/home/Events'),
      HomeTitle: () => import('@/components/home/HomeTitle'),
      LegendCard: () => import('@/components/home/LegendCard'),
      //Elixir: () => import('@/components/home/Elixir'),
      PoweredBy: () => import('@/components/home/PoweredBy'),
      MezcalStory: () => import('@/components/home/MezcalStory'),
      NotasCata: () => import('@/components/home/NotasCata'),
      Mixologia: () => import('@/components/home/Mixologia'),
      MedalDisplayRoom: () => import('@/components/home/MedalDisplayRoom'),
      //MedalShowroom: () => import('@/components/home/MedalShowroom'),
      //MedalShowcase: () => import('@/components/home/MedalShowcase'),
      OnlineStore: () => import('@/components/home/OnlineStore'),
      Parallax: () => import('@/components/home/Parallax'),
      ContactModal: () => import('@/components/service/ContactModal')
    },
    computed: {
      isOfAge: {
        get () {
          return this.$store.state.ofAge
        },
      },
      showModal: {
        get() {
          return this.$store.state.showModal
        },
      },
    },
    updated () {
      if (
        this.$store.state.ofAge &&
        this.$route.hash !== '#!' &&
        this.$route.hash.length > 0
      ) {
        this.$vuetify.goTo(this.$route.hash)
      }
    },
  }
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  ::selection {
    background: #fff6e7; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: #fff6e7; /* Gecko Browsers */
  }
  .elixir-teal {
    color: #4E7A7F;
  }
</style>
